$indent-top: 12px;

.prompt {
  width: 100%;
  max-width: 724px;
  box-sizing: border-box;
  background: rgba(23, 23, 23, 0.6);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
  backdrop-filter: blur(5px);
  border-radius: 16px 16px 0 0;
  padding: $indent-top 24px 40px;
  margin: 52px auto 0;

  &__header {
    display: flex;
    margin-bottom: 14px;
  }

  &__avatar {
    $size: 86px;
    @include size($size);

    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    margin: calc(-#{$size} / 2 - #{$indent-top}) 12px 0 0;
  }

  &__name {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;

    span {
      font-weight: 500;
      color: #E37B54;
    }
  }
}
