.input {
  width: 100%;

  &__item {
    $color: #d1d1d1;

    padding: 12px;
    background-color: #1B1826;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    color: #fff;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
      color: $color;
    }
    &::-moz-placeholder {
      color: $color;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: $color;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $color;
    }
  }

  &--white {
    .input {
      &__item {
        $color: #333;

        background-color: #fff;
        color: $color;
        border-color: #AA8FF6 !important;


        @media (min-width: 1023px) {
          &:hover {
            border-color: #8040E7 !important;
          }

          &:active {
            border-color: #8040E7 !important;
          }
        }


        &::-webkit-input-placeholder {
          color: #818181;
          font-weight: 300;
        }
        &::-moz-placeholder {
          color: #818181;
          opacity: 1;
          font-weight: 300;
        }
        &:-moz-placeholder {
          color: #818181;
          opacity: 1;
          font-weight: 300;
        }
        &:-ms-input-placeholder {
          color: #818181;
          font-weight: 300;
        }
      }
    }
  }
}
