.email-modal {
  overflow-y: auto;
  overflow-x: hidden;

  &__avatar {
    @include size(86px);

    border-radius: 50%;
    border: 1px solid #110F17;
    overflow: hidden;
    margin-right: 6px;
  }

  &__modal-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 672px;
    margin: auto auto 0;
    padding-bottom: 80px;
  }

  &__inner-wrapper {
    position: relative;

    @include breakpoint {
      margin: 0 24px;
    }
  }

  &__second-img {
    position: absolute;
    width: 608px;
    height: 548px;
    left: 50%;
    transform: translateX(-25%);
    bottom: 52px;

    @include breakpoint {
      width: 395px;
      height: 357px;
      bottom: 62px;
      transform: translateX(-15%);
    }
  }

  &__text {
    position: relative;
    z-index: 1;
    max-width: 340px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #E3E3E3;
    margin-bottom: 24px;

    span {
      font-weight: 500;
      color: #E37B54;
    }

    @include breakpoint {
      max-width: 218px;
      font-size: 12px;
      line-height: 16px;

      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__label {
    margin-bottom: 4px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #D1D1D1;
    position: relative;
    z-index: 1;
  }

  &__title-wrap {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
  }

  &__input-wrap {
    position: relative;
    z-index: 1;
    margin-bottom: 52px;

    @include breakpoint {
      margin-bottom: 28px;
    }
  }

  &__error {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #FF5353;
    margin-top: 4px;
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
  }

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #fff;

    @include breakpoint {
      font-size: 18px;
      line-height: 40px;
    }
  }
}
