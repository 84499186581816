@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Roboto:ital@0;1&display=swap');

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body, html {
  min-width: 100%;
  min-height: 100%;
  line-height: 1;
  text-transform: none;
  text-rendering: auto;
  visibility: visible;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  font-family: "Onest", sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img,
fieldset,
a img {
  border: none;
}

img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

a {
  text-decoration: none;
  transition: .3s;
  &:hover {
    text-decoration: underline;
  }
}
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

input[type='submit'],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  background: 0;
  border: 0;
}

input, textarea {
  resize: none;
  user-select: text;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: white !important;
  color: black !important;
}

input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  padding: 0;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

label {
  cursor: pointer;
}

textarea {
  outline: none;
  resize: none;
  &:active,
  &:focus {
    outline: none;
    resize: none;
  }
}

button {
  outline: none;
  border: none;
  &:active,
  &:focus {
    outline: none;
  }
}

\:active,
\:hover,
\:focus {
  outline: none;
  outline-offset: 0;
}

select {
  appearance: none;
}

* {
  box-sizing: border-box;
  font-family: "Onest", sans-serif;
}
