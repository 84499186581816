.loading-screen {
  height: 100%;
  overflow: hidden;
  position: relative;

  &__figure {
    position: absolute;
    top: -56px;
    left: 50%;
    height: 100%;
    max-width: 1000px;
    width: 100%;
    transform: translateX(-33%);

    img {
      object-fit: cover;
      object-position: center center;
    }
  }

  &__star {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @include size(100px);

    path {
      fill: #000;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: spin  2s infinite linear;

    @include breakpoint {
      @include size(48px);

      margin: 0 auto 16px;
    }
  }
}
