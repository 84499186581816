.chose {
  max-width: 756px;
  margin: 0 auto;
  padding: 8px 16px;
  box-sizing: border-box;

  @include breakpoint {
    padding: 8px 0 0 0;
  }

  &__buttons-wrap {
    box-sizing: border-box;
    height: 88px;
    display: flex;
    justify-content: center;
    padding: 20px 15px;
    max-width: 724px;
    border-radius: 24px;
    margin: 0 auto;
    background: rgba(23, 23, 23, 0.6);
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(17.9247px);

    @include breakpoint {
      height: 64px;
      padding: 12px 15px;
      border-radius: 0;
    }
  }

  &__button {
    $indent: 6px;

    min-height: 40px;
    width: calc(50% - #{$indent * 2});
    border: 1px solid #DC5A32;
    color: #DC5A32;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &:disabled {
      margin-left: 8px;
      background-color: #333333;
      border-color: #333333;
      color: #969696;
      cursor: auto;
    }
  }

  &__input {
    padding-right: 32px;

    @include breakpoint {
      padding: 8px 32px 8px 12px;
    }
  }

  &__submit-button {
    @include size(36px);
    @include flex(center);

    border-radius: 6px;
    background-color: #E37B54;
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);

    @include breakpoint {
      @include size(28px);
    }
  }

  &__form {
    @include flex(center);

    position: relative;
    min-height: 56px;
    box-sizing: border-box;
  }

  &__arrow {
    transform: rotate(-90deg);
    margin: 0 auto;
  }

  &__back-button {
    @include size(40px);

    text-align: center;
    margin-right: 8px;
    flex-shrink: 0;
    color: #D1D1D1;

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    @include breakpoint {
      @include size(36px);
    }
  }

  &--input {
    padding: 15px 16px;
    background-color: rgba(23, 23, 23, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(17.9247px);
    border-radius: 24px;

    @include breakpoint {
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      border: none;
    }
  }
}