.email {
  @include flex(between, column);

  flex: 1;
  height: 100%;
  background: transparent  center / cover no-repeat;

  &__wrapper {
    @include flex(center, column);

    flex: 1;
    height: 100%;
  }

  &__link {
    text-decoration: underline;
    color: #AA8FF6;
  }

  &__disclaimer {
    margin-top: 12px;
    color: #969696;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  &__wrap {
    width: 100%;
    max-width: 576px;

    @include breakpoint(lg) {
      max-width: 600px;
    }

    @include breakpoint {
      padding: 0 32px;
    }
  }

  &__title {
    @extend %title-small;

    margin-bottom: 24px;
    text-align: center;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  &__text {
    @extend %text;

    text-align: center;
    margin-bottom: 72px;

    @include breakpoint {
      margin-bottom: 48px;
    }
  }

  &__input-wrap {
    width: 100%;
    margin-bottom: 32px;
    position: relative;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    bottom: -12px;
    transform: translateY(100%);
    max-height: 250px;
    width: 100%;
    background-color: #fff;
    border: 1px solid var(--them-color);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px;
    overflow: auto;

    @include breakpoint(lg) {
      max-height: 200px;
    }

    @include breakpoint {
      bottom: auto;
      top: -11px;
      transform: translateY(-100%);
    }
  }

  &__dropdown-item {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    display: block;
    margin-bottom: 8px;
    width: 100%;
    color: #585858;

    &:hover {
      color: #8F62F0;
    }
  }

  &__error {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #FF5353;
    margin-top: 4px;
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
  }

  &__label {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #818181;
    margin-bottom: 4px;
  }

  &__headline {
    text-align: center;
    margin: -4px 0 36px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #6A6A6A;

    @include breakpoint {
      margin: 0 0 16px;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &--white {
    background: transparent  center left calc(50% + 100px) / 887px no-repeat;

    .email {
      &__title {
        font-size: 36px;
        line-height: 44px;
        color: #333;

        @include breakpoint {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 8px;
        }
      }

      &__text {
        margin-bottom: 48px;
        color: #585858;

        @include breakpoint {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }

      &__wrap {
        @include breakpoint {
          padding: 0 16px;
        }
      }

      &__input_wrap {
        @include breakpoint {
          margin-bottom: 36px;
        }
      }

      &__wrapper {
        @include breakpoint(lg) {
          justify-content: flex-end;
          padding-bottom: 80px;
        }

        @include breakpoint {
          padding-bottom: 48px;
        }
      }

      &__submit_button {
        &:disabled {
          background-color: #E3E3E3 !important;
          color: #C1C1C1 !important;
          pointer-events: none;

          path {
            stroke: #C1C1C1 !important;
          }
        }

        @media (min-width: 1023px) {
          &:hover {
            background-color: #C7B8FA !important;
            color: #301362 !important;

            path {
              stroke: #301362 !important;
            }
          }

          &:active {
            background-color: #AA8FF6 !important;
            color: #301362 !important;

            path {
              stroke: #301362 !important;
            }
          }
        }

        @include breakpoint {
          min-height: 48px;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    @include breakpoint(lg) {
      background-size: 587px;
    }

    @include breakpoint {
      background-size: contain;
      background-position: right -30px top 102px;
    }
  }
}
