.button {
  @include flex(center);

  width: 100%;
  max-width: 600px;
  background-color: var(--them-color);
  min-height: 56px;
  border-radius: 12px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;



  &__arrow {
    @include size(24px);

    margin-left: 4px;

    stroke: #fff;
  }

  &__small {
    min-height: 48px;
  }

  &__pellucid {
    min-height: 40px;
    background-color: transparent;
    color: var(--them-color);
    font-size: 16px;
    border: 1px solid var(--them-color);
    max-width: none;
  }

  &__green {
    font-size: 20px;
    font-weight: 700;
    min-height: 54px;
    background: #00C236;
    border-radius: 32px;
    text-transform: uppercase;

    &:hover {
      background: #00AA2F;
    }

    &:active {
      background: #00982A;

    }
  }

  &__full_width {
    max-width: 100%;
  }

  &__orange_gradient {
    font-size: 20px;
    font-weight: 800;
    min-height: 54px;
    background: linear-gradient(180deg, #FFBD7E 0%, #FFA762 50%, #FFBD7E 100%);
    border-radius: 32px;
    color: #333;

    &:hover {
      background: linear-gradient(180deg, #FFBD7E 0%, #F18D3F 50%, #FFBD7E 100%);
    }

    &:active {
      background: linear-gradient(180deg, #FFA762 0%, #F18D3F 50%, #FFA762 100%);
    }

    .button {
      &__arrow {
        stroke: #333;
      }
    }
  }
}
