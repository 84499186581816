body {
  background-color: var(--main-bg-color);
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

%title-big {
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  color: #fff;
}

%title-small {
  font-weight: 400;
  font-size: 40px;
  line-height: 32px;
  color: #fff;
}

%text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}