.header {
  $color: #969696;

  @include flex(between);

  position: relative;
  z-index: 10;
  width: 100%;
  padding: 48px 40px 0;

  @include breakpoint(lg) {
    padding: 24px 32px 0;
  }

  @include breakpoint {
    padding: 12px 16px 0;
  }

  &__coin-wrapper {
    min-width: 100px;
  }

  &__wraper {
    @include flex(center);

    flex: 1;
    max-width: 1006px;
    margin: 0 auto;

    @include breakpoint(lg) {
      width: 100%;
      margin-top: 26px;
    }

    @include breakpoint {
      margin-top: 20px;
    }
  }

  &__logo {
    width: 121px;
    height: 48px;
    cursor: pointer;

    img {
      @include size(100%);
    }

    @include breakpoint {
      width: 81px;
      height: 32px;
    }
  }

  &__button {
    @include flex(center);

    color: $color;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    min-height: 40px;
    min-width: 102px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $color;

    @include breakpoint {
      min-height: 32px;
      min-width: 78px;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }

    &--radius {
      border-radius: 12px;
    }
  }

  &__icon {
    @include size(20px);

    margin-right: 4px;
    transform: rotate(180deg);

    path {
      stroke: $color;
    }

    @include breakpoint {
      @include size(16px);
    }
  }

  &__coin-icon {
    @include size(28px)
  }

  &__coin-wrap {
    display: flex;
    align-items: center;
  }

  &__close-button {
    @include flex(center);

    width: 48px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid #585858;
    border-radius: 12px;
  }

  &__coin {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
    border-radius: 0 32px 32px 0;
    background-color: #110F17;
    min-width: 45px;
    padding-left: 7px;
    box-sizing: border-box;
  }

  &--flex {
    @include breakpoint(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &--coin {
    padding: 12px 144px 16px;
    background-color: #1B1826;
    border-radius: 0 0 20px 20px;

    @include breakpoint(lg) {
      padding: 6px 16px 9px;

      .header {
        &__logo {
          width: 82px;
          height: 32px;
        }
      }
    }
  }

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--main-bg-color);
  }

  &--row {
    flex-direction: row;
    align-items: flex-start;

    .header {
      &__button {
        border-radius: 12px;
        border-color: #333;
        color: #333;
        margin-left: 56px;

        @include breakpoint {
          margin-left: 0;
        }
      }

      &__wraper {
        margin-top: 0;

        @include breakpoint {
          margin-top: 16px;
        }
      }

      &__icon {
        path {
          stroke: #333;
        }
      }
    }

    @include breakpoint {
      flex-direction: column;
    }
  }

  &--indent {
    padding-bottom: 16px;
  }
}
