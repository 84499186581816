.answer {
  max-width: 724px;
  margin: 0 auto;

  @keyframes rotateBG {
    from {
     transform: rotate(0deg) scale(1, 16);
    }
    to {
      transform: rotate(360deg) scale(1, 16);
    }
  }

  @keyframes rotateBGmobile {
    from {
      transform: rotate(0deg) scale(1.2, 16);
    }
    to {
      transform: rotate(360deg) scale(1.2, 16);
    }
  }

  &__button {
    position: relative;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 12px;
    display: block;
    width: 100%;
    overflow: hidden;

    &:after {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-image: linear-gradient(143.57deg, #615BAA 1.41%, #00E0FF 100%);
      animation: rotateBG 5s infinite linear;
      will-change: transform;
    }

    @include breakpoint {
      $indent: 16px;

      width: calc(100% - #{$indent * 2});
      margin: 0 $indent 12px;

      &:after {
        animation: rotateBGmobile 5s infinite linear;
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #E3E3E3;
    padding: 12px;
    margin: 1px;
    display: block;
    width: calc(100% - 2px);
    background-color: #1B1826;
    border-radius: 12px;
    box-sizing: border-box;
  }

  @include breakpoint(lg) {
    max-width: 600px;
  }
}
