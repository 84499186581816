.first_screen {
  @include flex(between, column);

  flex: 1;
  height: 100%;
  box-sizing: border-box;

  &__wrapper {
    @include flex(between);

    @include breakpoint(lg) {
      flex-direction: column;
    }

    &--center {
      margin: auto;

      .first_screen {
        &__title {
          line-height: 54px;
          text-align: center;
          margin-bottom: 16px;

          @include breakpoint {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            text-align: left;
          }
        }
        &__text {
          text-align: center;
          color: #D9D9DE;

          @include breakpoint {
            text-align: left;
          }
        }
      }
    }
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: auto;
    width: 100%;

    @include breakpoint(lg) {
      padding: 60px 30px 0;
      box-sizing: border-box;
    }

    @include breakpoint {
      padding: 56px 20px 0;
    }

    &--center {
      align-items: center;
      overflow-x: hidden;
    }
  }

  &__figure {
    max-width: 887px;
    flex: 1;
    max-height: 881px;
    height: 100%;

    img {
      @include size(100%);
    }

    @include breakpoint(lg) {
      max-width: 560px;
      max-height: 563px;
      margin: 0 0 24px auto;
    }

    @include breakpoint {
      margin: 0 0 60px 0;
    }

    &--center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-39%, -50%);
      width: 100%;

      @include breakpoint(lg) {
        max-width: 598px;
        max-height: 600px;
        position: static;
        transform: translate(15%, 0);
        margin-bottom: -24px;
      }

      @include breakpoint {
        max-width: 340px;
        max-height: 340px;
        margin-bottom: 24px;
      }
    }
  }

  &__title {
    @extend %title-small;

    margin-bottom: 24px;

    @include breakpoint(lg) {
      text-align: center;
    }

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;

    }
  }

  &__text {
    @extend %text;

    margin-bottom: 72px;

    @include breakpoint(lg) {
      text-align: center;
    }

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 48px;
    }
  }

  &__wrap {
    flex: 1;
    padding-right: 20px;
    position: relative;

    @include breakpoint(lg) {
      @include flex(center, column);

      padding-right: 0;
    }

    @include breakpoint {
      justify-content: flex-start;
    }

    &--big {
      width: 640px;
      padding: 0;

      @include breakpoint(lg) {
        width: 100%;
      }
    }
  }

  &__nav {
    @include flex(center);

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #5D5E6C;
    margin: 45px 0 32px;
    position: relative;
    z-index: 10;

    a {
      font: inherit;
      color: inherit;
    }

    @include breakpoint {
      margin: 24px 0;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }
  }

  &__seporator {
    padding: 0 8px;
  }
}