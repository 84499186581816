.message {
  display: flex;
  align-items: flex-end;
  max-width: 724px;
  margin: 0 auto 24px;

  &__avatar {
    @include size(48px);

    border-radius: 8px;
    overflow: hidden;
    margin-right: 12px;
    flex-shrink: 0;
  }

  &__wrap {
    position: relative;
    padding: 12px 16px;
    border-radius: 8px 8px 8px 0;
    background-color: #8F62F0;
    max-width: 448px;

    &:after {
      @include size(6px);

      position: absolute;
      content: '';
      left: -5px;
      bottom: 0;
      background: transparent url('../images/messageLeft.svg')  center / cover no-repeat;
    }

    @include breakpoint {
      padding: 8px 12px;
    }
  }

  &__name {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #301362;
    margin-bottom: 2px;

    @include breakpoint {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #fff;

    strong {
      font-weight: 500;
    }

    @include breakpoint {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__right {
    flex-direction: row-reverse;

    .message {

      &__avatar {
        margin-right: 0;
        margin-left: 12px;
      }

      &__name {
        text-align: right;
      }

      &__wrap {
        background-color: #DC5A32;
        border-radius: 8px 8px 0 8px;

        &:after {
          left: auto;
          right: -5px;
          background-image: url('../images/messageRight.svg');
        }
      }
    }
  }

  @include breakpoint(lg) {
    max-width: 600px;
  }

  @include breakpoint {
    max-width: 100%;
    margin: 0 16px 20px;
  }
}
