.answer_item {
  padding: 16px;
  margin: 6px;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: #1B1826;

  @include breakpoint {
    padding: 8px;
  }

  &__title {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;

    @include breakpoint {
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      margin-bottom: 2px;
    }
  }

  &__text {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;

    @include breakpoint {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }
}