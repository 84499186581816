@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

.print-text {
  font-size: 18px;
  font-family: "PT Serif", serif;
  font-weight: 400;
  line-height: 28px;
  color: #D1D1D1;
  max-width: 724px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 24px;

  @include breakpoint(lg) {
    max-width: 600px;
  }

  @include breakpoint {
    max-width: 100%;
    margin: 0 16px;
    padding-bottom: 20px;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

  }
}
