.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
  flex-direction: column;

  &__inner-wrap {
    @include size(100%);

    background: transparent  center / cover no-repeat;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__overlay {
    @include size(100%);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: #000;

    &--blure {
      background: rgba(23, 23, 23, 0.8);
      border-top: 1px solid rgba(0, 0, 0, 0.25);
      box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
      backdrop-filter: blur(5px);
    }

    &--white {
      background-color: var(--main-bg-color);
    }
  }

  &__guaranteed {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
    color: #E3E3E3;
  }

  &__cross {
    @include size(24px);

    margin-left: 4px;

    @include breakpoint {
      @include size(20px);
    }
  }

  &__back-button {
    @include flex(center);

    position: absolute;
    right: 40px;
    top: 48px;
    border: 1px solid #AAAAAA;
    border-radius: 12px;
    color: #aaa;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    min-width: 140px;
    min-height: 48px;
    z-index: 15;

    @include breakpoint(lg) {
      right: 32px;
      top: 24px;
    }

    @include breakpoint {
      min-width: 92px;
      min-height: 32px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  &__body {
    overflow: hidden;
    .sales__review-wrap {
      &:before,
      &:after {
        z-index: -1;
      }
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #fff;
    margin-bottom: 24px;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }

  &__wrapper {
    @include flex(center, column, $align-items: flex-start);

    width: 100%;
    max-width: 650px;
    margin: 0 auto;
   flex: 1;

    @include breakpoint {
      padding: 0 16px;
    }

    &--big {
      max-width: 704px;
    }
  }

  &__headline {
    color: #D1D1D1;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 24px;

    span {
      color: #DC5A32;
    }

    @include breakpoint {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__divader {
    border: 1px solid #818181;
    width: 50%;
  }

  &__divader-wrap {
    @include flex(between);

    width: 100%;
  }

  &__buttons-wrap {
    @include flex(between);

    margin: 16px -6px;
    width: 100%;

    @include breakpoint {
      flex-direction: column;

      margin: -6px 0;
    }
  }

  &__batton-pay {
    @include flex(center);

    min-height: 58px;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    color: #E3E3E3;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin: 0 6px;
    width: 100%;
    transition: .3s;
    padding: 16px 0;

    &:hover {
      transform: scale(1.03);
    }

    @include breakpoint {
      margin: 6px 0;
    }
  }

  &__separator {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #818181;
    margin: 0 12px;

    @include breakpoint {
      margin: 12px 0 18px;
    }
  }

  &__button {
    @include flex(center);

    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #fff;
    background: #8F62F0;
    border-radius: 12px;
    width: 100%;
    min-height: 48px;
    margin-bottom: 16px;
    transition: .3s;

    &:hover {
      transform: scale(1.03);
    }

    @include breakpoint {
      margin-bottom: 0;
    }
  }

  &__total {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #D1D1D1;
    margin-bottom: 24px;

    span {
      font: inherit;
      text-align: left;
      color: #1AE5F6;
    }

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__discount-present {
    @include flex(center);

    background: linear-gradient(94.09deg, #F4994B 3.75%, #FF007F 94.56%);
    position: relative;
    min-width: 360px;
    min-height: 136px;
    gap: 8px;
    border-radius: 160px;
    border: 2px solid #F83B3B;
    overflow: hidden;
    margin-bottom: 48px;

    &:after {
      @include size(100%);
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url('../images/stars.svg')  center / cover no-repeat;
    }

    span {
      position: relative;
      z-index: 1;
      color: #fff;
      font-size: 64px;
      font-weight: 800;
      line-height: 32px;
    }

    @include breakpoint(lg) {
      min-width: 243px;
      min-height: 104px;
      margin-bottom: 24px;
    }
  }

  &__discount {
    @include flex(center, column);
    @include size(100%);

    max-width: 644px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @include breakpoint {
      box-sizing: border-box;
      padding: 0 32px;
    }
  }

  &__blure {
    @include size(100%);

    top: 0;
    left: 0;
    box-sizing: border-box;
    position: absolute;
    background: rgba(23, 23, 23, 0.9);
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(5px);
  }

  &__discount-title {
    @extend %title-small;

    text-align: center;
    margin-bottom: 24px;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  &__discount-text {
    @extend %text;

    text-align: center;
    margin-bottom: 48px;
    color: #D9D9DE;

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &__discount-button {
    @include flex(center);

    width: 100%;
    min-height: 56px;
    background-color: #1AE5F6;
    border-radius: 16px;
    color: #333;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;

    @include breakpoint {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      min-height: 48px;
      border-radius: 12px;
    }

    &--orange {
      background-color: #DC5A32;
      color: #fff;
    }
  }
}
