.chat-screen {
  @include flex(between, column);

  flex: 1;
  height: 100%;
  box-sizing: border-box;
  color: #fff;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    width: 100%;
  }

  &__wrap {
    max-width: calc(1074px + (32px * 2));
    width: 100%;
    margin: 0 auto;
  }

  &__last-item {
    padding-top: 40vh;
  }

  &__figure-wrap {
    $indent: 32px;

    flex-shrink: 0;
    height: 428px;
    width: 100%;
    max-width: calc(1074px + #{$indent * 2});
    margin: 32px auto;

    @include breakpoint(lg) {
      height: 360px;
      margin: 24px auto;
    }

    @include breakpoint {
      margin: -20px 0 24px 0;
    }
  }

  &__figure {
    height: 100%;
    max-width: 1074px;
    border-radius: 24px;
    overflow: hidden;
    margin: 0 32px;

    img {
      object-fit: contain;
      object-position: top center;
    }

    @include breakpoint {
      border-radius: 0 0 24px 24px;
      margin: 0;
    }
  }

  &__inner-wrap {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;
  }
}
