.divider {
  @include flex(between, column, $justify-content: flex-start);

  flex: 1;
  height: 100%;

  &__wrapper {
    @include flex(between, column);
    @include size(100%);

    max-width: 1183px;
    max-height: 789px;
    background: transparent  center / contain no-repeat;
    margin: 32px 0 20px;
    box-sizing: border-box;

    @include breakpoint(lg) {
      margin: 56px 0 20px;
      padding: 0 84px;
    }

    @include breakpoint {
      padding: 0 16px;
      margin: 48px 0 16px;
    }
  }

  &__button {
    max-width: 1024px;
  }

  &__title {
    @extend %title-big;

    width: 100%;
    text-align: center;

    @include breakpoint(lg) {
      text-align: left;
    }

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }

    &--big {
      max-width: none;
    }

    &--bounded {
      max-width: 1024px;
    }
  }
}
