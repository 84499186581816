@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin map-style($font-type, $color: null) {
  @each $key, $value in $font-type {
    #{$key}: $value;
  }
}

@mixin flex(
  $type: default,
  $direction: row,
  $display: flex,
  $align-items: null,
  $justify-content: null
) {
  @if $type == center {
    align-items: center;
    justify-content: center;
  }

  @else if $type == between {
    align-items: center;
    justify-content: space-between;
  }

  display: $display;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin breakpoint($class: xs) {
  @if $class == lg {
    @media (max-width: 1199px) {
      @content;
    }
  } @else {
      @media (max-width: 767px) {
        @content;
      }
  }
}