.multi_first {
  @include flex(between, column);

  flex: 1;
  height: 100%;
  box-sizing: border-box;
  color: #fff;

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow: auto;
    width: 100%;

    @include breakpoint(lg) {
      flex-direction: column;
    }
  }

  &__figure {
    position: relative;
    max-width: 68.5%;
    width: 100%;

    img {
      object-fit: cover;
      object-position: center center;
    }

    @include breakpoint(lg) {
      height: 512px;
      max-width: 100%;
      margin: -72px 0 0 0;
    }

    @include breakpoint {
      height: 416px;
      margin: -44px 0 -67px 0;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    max-width: 330px;

    @include breakpoint {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @include breakpoint {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__inner-wrap {
    margin-right: 80px;
    max-width: 460px;
    flex-shrink: 0;

    @include breakpoint(lg) {
      display: flex;
      flex-direction: column-reverse;
      max-width: 580px;
      margin:  0 auto;
    }

    @include breakpoint {
      padding: 0 16px;
    }
  }

  &__content-wrap {
    @include breakpoint(lg) {
      padding: 0 24px 40px;
    }
  }

  &__list {
    margin-top: 24px;

    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      margin-bottom: 8px;

      @include breakpoint {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    @include breakpoint {
      margin-top: 22px;
    }
  }

  &__box {
    margin-top: 24px;
    padding: 24px;
    box-sizing: border-box;
    background: rgba(23, 23, 23, .6);
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(17.9247px);

    @include breakpoint(lg) {
      margin: 0 0 32px 0;
    }

    @include breakpoint {
      padding: 16px;
      margin-bottom: 78px;
    }
  }

  &__headline {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 8px;

    @include breakpoint {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 12px;

    @include breakpoint {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }

  &__statistic {
    display: flex;
    align-items: center;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__statistic-list {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__statistic_icon {
    @include size(20px);

    margin-right: 4px;
  }

  &__star {
    @include size(12px);

  margin-right: 8px;
  }

  @include breakpoint(lg) {
    position: relative;

    &:after {
      position: absolute;
      width: 100%;
      height: 600px;
      content: '';
      bottom: 0;
      right: 0;
      background: transparent url('../../images/stars-bg.svg') left bottom / cover no-repeat;
    }
  }
}
