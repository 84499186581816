.progress {
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background-color: #333;
  margin: 0 24px;

  @include breakpoint {
    margin: 0 0 0 16px;
  }

  &__item {
    height: 100%;
    border-radius: 4px;
    background-color: var(--them-color);

    &--orange-gradient {
      background: linear-gradient(180deg, #FFBD7E 0%, #FFA762 50%, #FFBD7E 100%);
    }
  }

  &--white {
    margin: 0 0 0 24px;
    background-color: #D1D1D1;
  }
}
