.image-chat {
  $indent: 32px;

  max-width: calc(1074px + (#{$indent} * 2));
  height: 428px;
  margin: 0 auto $indent;

  &__figure {
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    background: transparent center / cover no-repeat;
    margin: 0 $indent;

    @include breakpoint {
      margin: 0;
    }
  }

  @include breakpoint(lg) {
    height: 360px;
  }

  @include breakpoint {
    height: 280px;
    margin: 0 16px 20px;
  }
}
