.plan-card {
  $popular-color: #DC5A32;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 34px 10px 16px;
  border: 2px solid #36304A;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 16px;
  position: relative;
  background-color: #1B1826;

  @include breakpoint {
    padding: 16px;
  }

  &__tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    padding: 3px 52px 5px;
    border-radius: 0 0 16px 16px;
    background-color: $popular-color;

    @include breakpoint {
      transform: translateX(0);
      left: auto;
      right: 0;
      border-radius: 0 14px 0 16px;
    }
  }

  &__period {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

    @include breakpoint {
      font-size: 16px;
    }
  }

  &__old-price {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    position: relative;
    color: #969696;
    text-align: left;

    &:after {
      display: block;
      content: '';
      width: calc(100% + 4px);
      height: 2px;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      background-color: #CE4428;
    }

    span {
      font-size: 20px;
    }

    @include breakpoint {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;

      span {
        font-size: 12px;
        line-height: normal;
      }
    }
  }

  &__price {
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    color: #fff;

    span {
      font-size: 20px;
    }

    @include breakpoint {
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;

      span {
        font-size: 12px;
        line-height: normal;
      }
    }
  }

  &__day {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 43px;

    strong {
      font-size: 40px;
      font-weight: 700;
      line-height: 56px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      line-height: 48px;
    }

    @include breakpoint {
      font-size: 12px;
      font-weight: 300;
      line-height: normal;

      strong {
        font-size: 24px;
        font-weight: 700;
        line-height: 20px;
      }

      span {
        font-size: 12px;
        line-height: normal;
      }
    }
  }

  &__wraper {
    @include flex(center);
  }

  &__inner-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__figure {
    @include size(24px);
    @include flex(center);

    box-sizing: border-box;
    padding: 2px;
    border-radius: 50%;
    background-color: #fff;
    margin-left: 8px;
  }

  &__check {
    @include size(24px);

    position: relative;
    border-radius: 50%;
    background-color: #110F17;
    border: 1px solid #969696;
    margin-right: 9px;

    span {
      @include size(12px);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    @include breakpoint {
      @include size(16px);

      margin-right: 16px;

      span {
        @include size(8px);
      }
    }
  }

  &--popular {
    border-color: $popular-color;
  }

  &--active {
    &.plan-card {
      &--popular {
        .plan-card__check {
          span {
            background-color: $popular-color;
          }
        }
      }
    }
  }

  &--white {
    background-color: #fff;
    border-color: #E3E3E3;

    @media (min-width: 1023px) {
      &:hover {
        background-color: #FAE6DA;
        border-color: #CE4428;

        .plan-card {
          &__tooltip {
            background-color: #CE4428;
          }
        }
      }

      &:active {
        border-color: #CE4428;
        background-color: #F3CBB5;

        .plan-card {
          &__tooltip {
            background-color: #CE4428;
          }
        }
      }
    }

    @include breakpoint {
      padding: 12px 16px 36px;
    }

    .plan-card {
      &__period {
        color: #CE4428;

        @include breakpoint {
          font-size: 18px;
          margin-right: -60px;
          margin-bottom: 8px;
        }
      }

      &__old-price {
        @include breakpoint {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 16px;
        }
      }

      &__price {
        color: #333;

        i {
          font-style: normal;
          font-size: 16px;
          font-weight: 400;
          line-height: 56px;

          @include breakpoint {
            display: block;
            line-height: 1;
            margin-bottom: -16px;
          }
        }

        @include breakpoint {
          font-size: 20px;
          line-height: 1;
        }
      }

      &__check {
        @include breakpoint {
          @include size(24px);

          margin-bottom: auto;
          flex-shrink: 0;

          span {
            @include size(12px);
          }
        }
      }

      &__day {
        color: #333;

        strong {
          @include breakpoint {
            font-size: 20px;
          }
        }
      }

      &__check {
        background-color: #fff;
      }

      &__tooltip {
        display: flex;
        align-items: center;

        @include breakpoint {
          padding: 4px 16px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
    &.plan-card {
      &--popular {
        background-color: #FDF5EF;
        border-color: #DC5A32;

        @media (min-width: 1023px) {
          &:hover {
            background-color: #FAE6DA;
          }

          &:active {
            background-color: #F3CBB5;
          }
        }
      }
      &--active {
        border-color: #CE4428 !important;

        .plan-card {
          &__check {
            span {
              background-color: #CE4428 !important;
            }
          }
        }
      }
    }
  }

  &--green {
    &.plan-card {
      &--popular {
        border-color: #00C236;
      }

    }
    .plan-card {
      &__tooltip {
        background-color: #00C236;
      }
    }
  }
}
