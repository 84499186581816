.load {
  @include flex(between, column);
  @include size(100%);

  flex: 1;
  position: relative;
  overflow: hidden;

  &__wrapper {
    @include flex(center, column);
    @include size(100%);

    flex: 1;
    max-width: 640px;
    position: relative;
    z-index: 1;

    @include breakpoint(lg) {
      max-width: 600px;
    }

    @include breakpoint {
      padding: 0 32px;
    }
  }

  &__figure {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -42%);
    width: 877px;
    height: 881px;
    background: transparent  top / contain no-repeat;

    @include breakpoint(lg) {
      width: 600px;
      height: 579px;
      top: 100px;
      transform: translate(-40%, 0);
    }

    @include breakpoint {
      width: 335px;
      height: 336px;
      transform: translate(-50%, 0);
    }
  }

  &__star {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @include size(100px);

    margin: 0 auto 72px;
    animation: spin  2s infinite linear;

    path {
      fill: #fff;
    }

    @include breakpoint {
      @include size(48px);

      margin: 0 auto 16px;
    }
  }

  &__title {
    @extend %title-small;

    text-align: center;
    margin-bottom: 24px;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  &__text {
    @extend %text;

    text-align: center;
    margin-bottom: 72px;

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 16px;
    }
  }

  &__inner-wrap {
    width: 100%;
  }

  &__procent {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: right;
    color: #fff;
    margin-bottom: 12px;
    width: 100%;
  }

  &__progress-wrap {
    margin: 0 -24px;
    width: 100%;

    @include breakpoint {
      margin: 0 0 0 -16px;
    }
  }

  &--white {
    background: transparent  center left calc(50% + 100px) / 887px no-repeat;

    .load {
      &__star {
        path {
          fill: var(--them-color);
        }
      }

      &__text {
        color: #6A6A6A;
      }

      &__title {
        color: #301362;
      }

      &__procent {
        color: #333;
      }
    }
  }

  @include breakpoint(lg) {
    background-size: 587px;
  }

  @include breakpoint {
    background-size: contain;
    background-position: center;
  }

  &--green {
    .load {
      &__text {
        color: #D1D1D1;
      }
    }
  }
}
