.stripe_form {
  width: 100%;
  padding: 30px 0 90px;

  &__wrap {
    width: 100%;
  }

  &__buttons {
    margin-bottom: 16px;
  }

  &__label {
    margin-bottom: 4px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #D1D1D1;
    position: relative;
    z-index: 1;
  }


  &__title {
    font-size: 40px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #fff;
    margin-bottom: 24px;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }

  &__headline {
    color: #D1D1D1;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 24px;

    span {
      color: #DC5A32;
    }

    @include breakpoint {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__button {
    @include flex(center);

    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #fff;
    background: #8F62F0;
    border-radius: 12px;
    width: 100%;
    min-height: 48px;
    margin: 16px 0;
    transition: .3s;

    &:hover {
      transform: scale(1.03);
    }
  }

  &__error {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #DC5A32;
    margin-bottom: 24px;
  }

  &__total {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #D1D1D1;
    margin-bottom: 24px;

    span {
      font: inherit;
      text-align: left;
      color: #1AE5F6;
    }

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__star {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @include size(100px);

    path {
      fill: #fff;
    }

    margin: 0 auto 72px;
    animation: spin  2s infinite linear;

    @include breakpoint {
      @include size(48px);

      margin: 0 auto 16px;
    }
  }

  &__forms-wrap {
    position: relative;
  }

  &__load-wrap {
    @include size(100%);

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    z-index: 10;
    min-height: 200px;
  }

  &--white {
    .stripe_form {
      &__title,
      &__headline,
      &__total {
        color: #333;
      }

      &__label {
        color: #969696;
      }

      &__load-wrap {
        background-color: #F7F7F7;
      }

      &__star {
        path {
          fill: #8F62F0;
        }
      }

      &__button {
        background-color: #DFD7FD;
        color: #8040E7;
      }

      &__headline,
      &__total {
        span {
          color: #8F62F0 !important;
        }
      }
    }
  }
}
