.question_screen {
  @include flex(between, column);

  flex: 1;
  height: 100%;
  overflow: hidden;

  &__wrapper {
    @include flex(between, column);
    @include size(100%);

    flex: 1;
    padding: 32px 0;
    max-width: 1140px;
    margin: 0 auto;

    @include breakpoint(lg) {
      padding: 56px 0 32px;
    }

    @include breakpoint {
      padding: 48px 0 32px;
    }
  }

  &__title {
    @extend %title-big;

    text-align: center;
    padding-bottom: 40px;
    max-width: 1024px;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      padding-bottom: 24px;
    }
  }

  &__item-wrap {
    @include size(100%);

    max-width: 640px;
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
    margin-bottom: 94px;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  &__item {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    min-height: 56px;
    padding: 17px 48px;
    border: 2px solid transparent;
    border-radius: 12px;
    background-color: #36304A;
    margin-bottom: 16px;
    transition: .3s;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;


    &:hover {
      transform: scale(1.03);
    }

    &--active {
      background-color: #fff;
    }

    &--green {
      padding: 12px 48px;
      border-radius: 32px;

      &.question_screen {
        &__item {
          &:hover {
            color: #fff !important;

            .question_screen {
              &__check {
                span {
                  background-color: #fff!important;
                }
              }
            }
          }

          &--active {
            background-color: #00C236;
            border-color: #00C236 !important;
            color: #fff !important;
          }
        }
      }
    }

    &--orange_gradient {
      padding: 12px 48px;
      border-radius: 32px;

      &.question_screen {
        &__item {
          &:hover {
            color: #fff !important;

            .question_screen {
              &__check {
                span {
                  background-color: #fff!important;
                }
              }
            }
          }

          &--active {
            background: linear-gradient(180deg, #FFBD7E 0%, #FFA762 50%, #FFBD7E 100%);
            border-color: transparent !important;
            color: #110F17 !important;

            &:hover {
              color: #110F17 !important;
            }
          }
        }
      }
    }

    &--animated {
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }

      animation: scale 0.8s ease-in-out;
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: 0 - (($i / 10))+s;
        }
      }
    }

    @include breakpoint(lg) {
      &:hover {
        transform: scale(1);
      }
    }
  }

  &__check {
    @include size(24px);

    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    border: 1px solid #5D5E6C;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    z-index: 1;

    span {
      @include size(12px);

      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
  }
}
